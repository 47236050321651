<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      Mapa de Encomendas
    </v-subheader>

    <v-card>
      <v-card-title class="primary white--text d-flex justify-space-between">
        <span>Pesquisar</span>
      </v-card-title>
      <v-card-text class="pt-7 px-7">
        <v-row dense>
          <v-col cols="12" md="4">
            <span class="primary--text">Origem</span>
            <v-autocomplete
              :items="trechos"
              item-text="nome"
              item-value="id"
              prepend-inner-icon="mdi-bus"
              v-model="origem"
              clearable
              placeholder="Informe a origem"
              @change="getHorario()"
            />
          </v-col>
          <v-col cols="12" md="4">
            <span class="primary--text">Destino</span>
            <v-autocomplete
              :items="trechos"
              item-text="nome"
              item-value="id"
              prepend-inner-icon="mdi-map-marker"
              v-model="destino"
              clearable
              placeholder="Informe o destino"
              @change="getHorario()"
            />
          </v-col>
          <v-col cols="12" md="2">
            <span class="primary--text">Data</span>
            <Calendar @dateValue="[(data = $event), getHorario()]" />
          </v-col>
          <v-col cols="12" md="2">
            <span class="primary--text">Horário</span>
            <v-select
              v-model="viagemId"
              :items="horarios"
              item-text="hora"
              item-value="id"
              placeholder="Selecione o horário"
              class="mt-3 custom-solo-selection"
              solo
              no-data-text="Sem horários"
              @change="search()"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" v-if="result">
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6">
            <span class="black--text body-2">
              Linha: <strong>{{ result.linha }}</strong>
            </span>
          </v-col>
          <v-col cols="12" md="6">
            <span class="black--text body-2">
              Veículo: <strong>{{ result.veiculo }}</strong>
            </span>
          </v-col>
          <v-col cols="12" md="6">
            <span class="black--text body-2">
              Tipo de poltrona: <strong>{{ result.tipoServico }}</strong>
            </span>
          </v-col>
          <v-col cols="12" md="6">
            <span class="black--text body-2">
              Poltronas: <strong>{{ result.qtdPoltronas }}</strong>
            </span>
          </v-col>
        </v-row>

        <v-simple-table class="mt-7" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left black--text">CIDADE</th>
                <th class="text-center black--text" style="width: 130px">
                  SOBEM
                </th>
                <th class="text-center black--text" style="width: 130px">
                  DESCEM
                </th>
                <th class="text-center black--text" style="width: 130px">
                  EMBARCADOS
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in result.localidades" :key="item.id">
                <td>{{ item.cidade }}</td>
                <td class="text-center">{{ item.sobem }}</td>
                <td class="text-center">{{ item.descem }}</td>
                <td class="text-center">{{ item.embarcados }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-simple-table class="mt-7" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left black--text">REMETENTE</th>
                <th class="text-center black--text">DOC.</th>
                <th class="text-left black--text">DESTINATÁRIO</th>
                <th class="text-center black--text">CÓD. BARRA</th>
                <th class="text-center black--text">NATUREZA</th>
                <th class="text-center black--text">QTD.</th>
                <th class="text-center black--text">ORIGEM</th>
                <th class="text-center black--text">DESTINO</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in result.encomendas" :key="item.id">
                <td>{{ item.remetente }}</td>
                <td class="text-center">{{ item.cnpj_cpf }}</td>
                <td>{{ item.destinatario }}</td>
                <td class="text-center">{{ item.cod_barras }}</td>
                <td class="text-center">{{ item.natureza }}</td>
                <td class="text-center">{{ item.quant_volumes }}</td>
                <td class="text-center">{{ item.origem }}</td>
                <td class="text-center">{{ item.destino }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import BackArrow from "../../../components/BackArrow/index.vue";
import Helpers from "@/mixins";
import Calendar from "@/components/Calendars.vue";
export default {
  mixins: [Helpers],
  components: {
    BackArrow,
    Calendar,
  },
  data: () => ({
    trechos: [],
    horarios: [],
    origem: null,
    destino: null,
    data: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    viagemId: null,
    result: null,
  }),
  created() {
    this.Helpers;
    this.loadLocations();
  },
  methods: {
    async loadLocations() {
      this.$store.dispatch("activeLoader", true);
      const { data } = await this.$http.get(`/pdv/localidades`);
      this.trechos = data;
      this.origem = this.$store.getters.getAgency.id_localidade;
      this.$store.dispatch("activeLoader", false);
    },
    async getHorario() {
      if (this.origem && this.data && this.destino) {
        this.$store.dispatch("activeLoader", true);
        const { data } = await this.$http.post("/viagens/horarios", {
          data: this.data,
          origem: this.origem,
          destino: this.destino,
        });
        this.horarios = data;
        this.result = null;
        this.viagemId = null;
        this.$store.dispatch("activeLoader", false);
      } else {
        this.viagemId = null;
        this.horarios = [];
        this.result = null;
      }
    },
    async search() {
      this.$store.dispatch("activeLoader", true);
      const { data } = await this.$http.post("/viagens/mapa/encomendas", {
        viagem: this.viagemId,
        origem: this.origem,
        destino: this.destino,
        data: this.data,
      });
      this.result = data;
      this.$store.dispatch("activeLoader", false);
    },
  },
};
</script>

<style lang="css">
.custom-solo-selection .v-input__control {
  min-height: 0 !important;
}

.custom-solo-selection .v-input__slot {
  padding: 2.5px 12px !important;
}

.custom-solo-selection .v-input__slot {
  box-shadow: none !important;
  background-color: #b3e6f8 !important;
}

.custom-solo-selection .v-text-field__details {
  margin-bottom: 0 !important;
}

.container-form {
  position: relative;
  min-height: calc(100vh - 70px);
}
</style>
